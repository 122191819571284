import { Button } from "react-bootstrap";

interface IBeforeFishingProps {
    location?: string;
    onDone: () => void;
}

export default function BeforeFishing(props: IBeforeFishingProps): JSX.Element {
    return (
        <div className='window location-chooser'>
            { props.location && <h3>Location: {props.location}</h3>}
            <Button variant='primary' onClick={props.onDone}>Start fishing</Button>
        </div>
    );
}
