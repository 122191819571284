import { useEffect } from "react";

interface IMovingProps {
    onDone: () => void;
};

export default function Moving(props: IMovingProps): JSX.Element {
    useEffect(() => {
        setTimeout(() => {
            props.onDone();
        }, 3000);
    // eslint-disable-next-line
    }, []);
    return (
        <p>Moving...</p>
    );
}
