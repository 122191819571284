import { useEffect } from "react";
import _ from 'lodash';

interface IFishingProps {
    onDone: () => void;
};

export default function Fishing(props: IFishingProps): JSX.Element {
    useEffect(() => {
        setTimeout(() => {
            props.onDone();
        }, _.random(4000, 10000));
    // eslint-disable-next-line
    }, []);
    return (
        <p>Fishing...</p>
    );
}
