import fishData from "../data/fishData";
import { FishEntry } from "./fishEntry";

class FishDataService {
    private _locations!: string[];
    private _fishNamesInLocation: Map<string, string[]> = new Map();
    private _fishInfoByName: Map<string, FishEntry> = new Map();

    public constructor() {
        this._processData();
    }

    public getLocations: () => string[] = () => {
        return this._locations;
    }

    public getFishInLocation: (loction: string) => string[] = (location) => {
        return this._fishNamesInLocation.get(location) || [];
    }

    public getInfoByName: (name: string) => FishEntry | undefined = (name: string) => {
        return this._fishInfoByName.get(name);
    }

    private _processData(): void {
        // get location names
        const regionsFound: Set<string> = new Set();
        fishData.forEach(fishEntry => {
            const regionEntry: string | null = fishEntry['NOAA Fisheries Region'];
            if (regionEntry) {
                const regionSplit: string[] = regionEntry.split(',').map(s => s.trim());
                regionSplit.forEach(region => {
                    regionsFound.add(region);
                });
            }
        });
        this._locations = Array.from(regionsFound);

        // get fish per location
        fishData.forEach(fishEntry => {
            const speciesName: string | null = fishEntry['Species Name'];
            const region: string | null = fishEntry['NOAA Fisheries Region'];

            if (speciesName && region) {
                if (!this._fishNamesInLocation.get(region)) {
                    this._fishNamesInLocation.set(region, []);
                }
                this._fishNamesInLocation.get(region)?.push(speciesName);
            }
        });

        // get fish data by name
        fishData.forEach(fishEntry => {
            const speciesName: string | null = fishEntry['Species Name'];
            if (speciesName) {
                this._fishInfoByName.set(speciesName, fishEntry);
            }
        });
    }
};

const FishData: FishDataService = new FishDataService();

export default FishData;
