import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
import { Animation, Engine, Scene, ArcRotateCamera, Vector3, HemisphericLight, MeshBuilder, StandardMaterial, Color3, Color4, EasingFunction, SineEase } from "@babylonjs/core";
import MeshCreator from "./MeshCreator";
import { Phase } from "../components/Application";

export default class AppScene {
    public static instance(): AppScene {
        if (!AppScene._instance) {
            AppScene._instance = new AppScene();
        }
        return AppScene._instance;
    }
    private static _instance?: AppScene;

    private _camera: ArcRotateCamera;
    private _scene: Scene;
    private _meshCreator: MeshCreator;

    private _isFishing: boolean = false;

    public updatePhase(newPhase: Phase, location: string | undefined): void {
        if (newPhase === Phase.fishing) {
            this._turnToFish();
        }
        if (newPhase === Phase.reeling) {
            this._onFishOn();
        }
        if (newPhase === Phase.learn) {
            this._onLearn();
        }
        if (newPhase === Phase.chooseLocation) {
            this._turnToFront();
        }
        if (newPhase === Phase.moving) {
            this._startMoving(location);
        }
        if (newPhase === Phase.beforeFishing) {
            this._endMoving();
        }
    }

    private constructor() {
        var canvas = document.createElement("canvas");
        canvas.width = 0;
        canvas.height = 0;
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        canvas.style.position = "absolute";
        canvas.style.top = "0";
        canvas.style.zIndex = "0";
        canvas.id = "appCanvas";
        document.body.appendChild(canvas);

        // initialize babylon scene and engine
        var engine = new Engine(canvas, true);
        var scene = new Scene(engine);
        this._scene = scene;
        scene.clearColor = new Color4(0.67, 0.8, 1, 1);
        window.addEventListener('resize', function(){ engine.resize(); });
    
        // WATER
        const water = MeshBuilder.CreateGround("ground", {width:100, height:100}, scene);
        const waterMaterial = new StandardMaterial('waterMaterial', scene);
        waterMaterial.diffuseColor = Color3.Blue();
        water.material = waterMaterial;

        // WATER ANIMATION
        const frameRate = 10;
        const bobAnimation = new Animation("yBob", "position.y", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
        const easingFunction = new SineEase();
        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        bobAnimation.setEasingFunction(easingFunction);
        bobAnimation.setKeys([
            { frame: 0, value: 0 },
            { frame: frameRate, value: -0.2 },
            { frame: 2 * frameRate, value: 0 }
        ]);
        water.animations.push(bobAnimation);
        scene.beginAnimation(water, 0, 2 * frameRate, true, 0.2);

        const camera: ArcRotateCamera = new ArcRotateCamera("Camera", Math.PI / 2, 1.38, 0.0, new Vector3(0, 1.6, 0), scene);
        camera.fov  = 1.0;
        camera.minZ = 0.1;
        this._camera = camera;
        new HemisphericLight("light1", new Vector3(1, 1, 0), scene);

        // hide/show the Inspector
        window.addEventListener("keydown", (ev) => {
            // Shift+Ctrl+Alt+I
            if (ev.shiftKey && ev.ctrlKey && ev.altKey && ev.keyCode === 73) {
                if (scene.debugLayer.isVisible()) {
                    scene.debugLayer.hide();
                } else {
                    scene.debugLayer.show();
                }
            }
        });

        this._meshCreator = new MeshCreator(scene, waterMaterial);
        this._meshCreator.createLocation('default');

        this._meshCreator.createBoat(scene);
        this._meshCreator.createFishingRod(scene);

        // run the main render loop
        engine.runRenderLoop(() => {
            scene.render();
        });
    }

    private _turnToFish(): void {
        if (this._isFishing) {
            this._meshCreator.setFishingRodVisible(true);
            return;
        }
        this._isFishing = true;

        const turnAnimation: Animation = new Animation('turn', 'alpha', 10, Animation.ANIMATIONTYPE_FLOAT);
        const easingFunction = new SineEase();
        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        turnAnimation.setEasingFunction(easingFunction);
        turnAnimation.setKeys([
            { frame: 0, value: Math.PI / 2 },
            { frame: 10, value: Math.PI * 1.5 }
        ]);
        this._camera.animations.push(turnAnimation);
        this._scene.beginAnimation(this._camera, 0, 10, false, 1, () => {
            this._camera.animations = [];
            setTimeout(() => {
                this._meshCreator.setFishingRodVisible(true);
            }, 400);
        }, undefined, true);
    }

    private _onFishOn(): void {
        this._meshCreator.setFishingRodAnimated(true);
    }

    private _onLearn(): void {
        this._meshCreator.setFishingRodAnimated(false);
        this._meshCreator.setFishingRodVisible(false);
    }

    private _turnToFront(): void {
        if(!this._isFishing) {
            return;
        }
        this._isFishing = false;

        this._meshCreator.setFishingRodVisible(false);

        const turnAnimation: Animation = new Animation('turn', 'alpha', 10, Animation.ANIMATIONTYPE_FLOAT);
        const easingFunction = new SineEase();
        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
        turnAnimation.setEasingFunction(easingFunction);
        turnAnimation.setKeys([
            { frame: 0, value: Math.PI * 1.5 },
            { frame: 10, value: Math.PI / 2 }
        ]);
        this._camera.animations.push(turnAnimation);
        this._scene.beginAnimation(this._camera, 0, 10, false, 1, () => {
            this._camera.animations = [];
        }, undefined, true);
    }

    private _startMoving(location: string | undefined): void {
        this._meshCreator.startMoving(location);
    }

    private _endMoving(): void {
        this._meshCreator.endMoving();
    }
}