import Button from 'react-bootstrap/Button';

interface ITitleProps {
    onStart: () => void
}

export function Title(props: ITitleProps): JSX.Element {
    return (
        <div className='window'>
            <h1 style={{ marginBottom: '0' }}>KJ Fishing</h1>
            <a style={{ display: 'block', marginBottom: '15px' }} href='https://kjfishing.net'>kjfishing.net</a>
            <Button variant='primary' onClick={props.onStart}>Start</Button>
        </div>
    );
}