import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { FishEntry, ImageEntry } from '../services/fishEntry';

interface ILearnProps {
    onDone: () => void;
    fishInfo?: FishEntry;
};

export default function Learn(props: ILearnProps): JSX.Element {
    if (!props.fishInfo) {
        return <p>no info :(</p>;
    }
    const images: ImageEntry[] = [];

    if (props.fishInfo?.['Species Illustration Photo']) {
        images.push(props.fishInfo['Species Illustration Photo']);
    }

    if (!!props.fishInfo['Image Gallery']) {
        if (Array.isArray(props.fishInfo['Image Gallery'])) {
            props.fishInfo['Image Gallery'].forEach(image => {
                images.push(image);
            });
        } else {
            images.push(props.fishInfo['Image Gallery']);
        }
    }

    return (
        <div className='window learn'>
            { props.fishInfo?.['Species Name'] && (
                <>
                    <h2>You caught a</h2>
                    <h1>{props.fishInfo['Species Name']}!</h1>
                </>
            )}
            <Carousel interval={null} variant="dark" className='image-carousel'> 
                {images.map((image, index) => (
                    <Carousel.Item key={index}>
                        <div className='image-content'> 
                            <img className='fish-image' src={image.src || ''} alt={image.alt ||''}/>
                            {/*{ image.title && (
                                <Carousel.Caption><h2>{ image.title }</h2></Carousel.Caption>
                            )}*/}
                            <h6>{ image.title }</h6>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
            <div style={{marginTop: '10px'}} />
            <div className='text-content'>
                <div className='text-inner-content'>
                    <h2>About</h2>
                    { infoLine(props.fishInfo, 'Scientific Name')}
                    { infoLine(props.fishInfo, 'Species Aliases', 'Other names')}
                    { infoLine(props.fishInfo, 'Physical Description')}
                    { infoLine(props.fishInfo, 'Color')}
                    { infoLine(props.fishInfo, 'Biology')}
                    <h2>Population</h2>
                    { justInfo(props.fishInfo, 'Population')}
                    { infoLine(props.fishInfo, 'Population Status')}
                    <h2>Habitat</h2>
                    { justInfo(props.fishInfo, 'Habitat')}
                    { infoLine(props.fishInfo, 'Habitat Impacts')}
                    { infoLine(props.fishInfo, 'Location')}
                    <h2>Nutrition</h2>
                    { justInfo(props.fishInfo, 'Health Benefits')}
                    { infoLine(props.fishInfo, 'Serving Weight')}
                    { infoLine(props.fishInfo, 'Calories')}
                    { infoLine(props.fishInfo, 'Carbohydrate')}
                    { infoLine(props.fishInfo, 'Cholesterol')}
                    { infoLine(props.fishInfo, 'Sugars, Total', 'Sugar')}
                    { infoLine(props.fishInfo, 'Sodium')}
                    { infoLine(props.fishInfo, 'Protein')}
                    { infoLine(props.fishInfo, 'Selenium')}
                    { infoLine(props.fishInfo, 'Fiber, Total Dietary', 'Fiber')}
                    { infoLine(props.fishInfo, 'Fat, Total')}
                    { infoLine(props.fishInfo, 'Saturated Fatty Acids')}
                    <h2>Cooking</h2>
                    { infoLine(props.fishInfo, 'Taste')}
                    { infoLine(props.fishInfo, 'Texture')}
                </div>
            </div>
            <p style={{ margin: '15px' }}>Source: <a target='_blank' rel='noreferrer' href='https://www.fishwatch.gov/'>www.fishwatch.gov</a></p>
            <Button variant='primary' style={{ marginBottom: '30px' }} onClick={ props.onDone }>done</Button>
        </div>
    );
}

function infoLine(info: FishEntry, key: keyof FishEntry, title?: string): JSX.Element | undefined {
    return info[key] && <p><b>{title || key}:</b> {processString(info[key])}</p>;
}

function justInfo(info: FishEntry, key: keyof FishEntry) {
    return info[key] && <p>{processString(info[key])}</p>;
}

function processString(str: string): string {
    return str
        .replaceAll(/<[^>]+>/g, '')
        .replaceAll('&nbsp;', ' ');
}