import React from "react";
import _ from "underscore";

import './components.css';
import AppScene from "../babylon/AppScene";
import FishData from "../services/fishDataService";
import Fishing from "./Fishing";
import Learn from "./Learn";
import LocationChooser from "./LocationChooser";
import { Title } from "./Title";
import Ending from "./Ending";
import Moving from "./Moving";
import Reeling from "./Reeling";
import BeforeFishing from "./BeforeFishing";

export const enum Phase {
    title = 'title',
    chooseLocation ='chooseLocation',
    moving = 'moving',
    beforeFishing = 'beforeFishing',
    fishing = 'fishing',
    reeling = 'reel',
    learn = 'learn',
    ending = 'ending'
}

interface IApplicationProps {}

interface IApplicationState {
    phase: Phase,
    chosenLocation?: string,
    chosenFish?: string,
    appScene: AppScene
}

export default class Application extends React.Component<IApplicationProps, IApplicationState> {
    public constructor(props: IApplicationProps) {
        super(props);
        this.state = {
            phase: Phase.title,
            appScene: AppScene.instance()
        };
    }

    public componentDidUpdate(_: IApplicationProps, prevState: IApplicationState): void {
        if (prevState.phase !== this.state.phase) {
            this.state.appScene.updatePhase(this.state.phase, this.state.chosenLocation);
        }
    }

    public render(): JSX.Element {
        if (this.state.phase === Phase.title) {
            return (
                <Title
                    onStart={() => {
                        this.setState({ phase: Phase.chooseLocation });
                    }}
                />
            )
        }
        if (this.state.phase === Phase.chooseLocation) {
            return (
                <LocationChooser
                    onLocationChosen={ (location: string) => {
                        this.setState({
                            phase: Phase.moving,
                            chosenLocation: location
                        });
                    }}
                    locations={ FishData.getLocations() }
                />
            );
        }
        if (this.state.phase === Phase.moving) {
            return (
                <Moving onDone={ () => {
                    this.setState({ phase: Phase.beforeFishing });
                }}/>
            );
        }
        if (this.state.phase === Phase.beforeFishing) {
            return (
                <BeforeFishing
                    location={this.state.chosenLocation}
                    onDone={() => { this.setState({ phase: Phase.fishing })}}
                />
            );
        }
        if (this.state.phase === Phase.fishing) {
            return (
                <Fishing onDone={ () => {
                    const fishList: string[] = FishData.getFishInLocation(this.state.chosenLocation!);
                    const chosenFishName: string | undefined = _.sample(fishList);
                    this.setState({
                        phase: Phase.reeling,
                        chosenFish: chosenFishName
                    });
                }}/>
            );  
        }
        if (this.state.phase === Phase.reeling) {
            return (
                <Reeling onDone={ () => {
                    this.setState({ phase: Phase.learn});
                }}/>
            );
        }
        if (this.state.phase === Phase.learn) {
            return (
                <Learn
                    fishInfo={ FishData.getInfoByName(this.state.chosenFish!) }
                    onDone={ () => {
                        this.setState({ phase: Phase.ending });
                    }}
                />
            );
        }
        if (this.state.phase === Phase.ending) {
            return (
                <Ending
                    onFishAgain={ () => {
                        this.setState({ phase: Phase.fishing });
                    }}
                    onMove={ () => {
                        this.setState({ phase: Phase.chooseLocation });
                    }}
                />
            );
        }
        return <p>...</p>;
    }
}