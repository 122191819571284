import { Button } from "react-bootstrap";

interface ILocationChooserProps {
    onLocationChosen: (locationName: string) => void,
    locations: string[]
}

export default function LocationChooser(props: ILocationChooserProps): JSX.Element {
    return (
        <div className='window location-chooser'>
            <h1>Where do you want to go?</h1>
            { props.locations.map((location: string, index: number) => (
                <Button
                    variant='dark'
                    key={index}
                    onClick={() => props.onLocationChosen(location)}
                >{ location }</Button>
            ))}
        </div>
    );
}
