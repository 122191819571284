import { Button } from "react-bootstrap";

interface IEndingProps {
    onFishAgain: () => void;
    onMove: () => void;
}

export default function Ending(props: IEndingProps): JSX.Element {
    return (
        <div className='window location-chooser'>
            <Button variant='primary' onClick={props.onFishAgain}>Fish again</Button>
            <Button variant='primary' onClick={props.onMove}>Go somewhere else</Button>
        </div>
    );
}
