import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

interface IReelProps {
    onDone: () => void
}

const clicks: number = 8;

export default function Reeling(props: IReelProps): JSX.Element {
    const [reels, setReels] = useState<number>(0);
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        if (reels >= clicks && !isReady) {
            setIsReady(true);
            setTimeout(() => {
                props.onDone();
            }, 300);
        }
    }, [reels, isReady, props]);

    return (
        <div className='window reeling'>
            <h1>Fish on!</h1>
            <div className={ isReady ? 'power-row power-ready' :'power-row'}>
                {[...Array(clicks)].map((_, index) => (
                    <div className='power-segment' key={index}>
                        { reels > index ? '!' : '.'}
                    </div>
                ))}
            </div>
            <Button
                className='reel-button'
                disabled={isReady}
                variant='danger'
                onClick={() => {
                    setReels(reels + 1);

                }}
            >Reel</Button>
        </div>
    );
}
