import _ from 'lodash';
import { AbstractMesh, Animatable, Animation, Color3, EasingFunction, Mesh, MeshBuilder, Scene, SceneLoader, SineEase, StandardMaterial, Vector3 } from "@babylonjs/core";

export default class MeshCreator {
    private _frameRate: number = 10;

    private _scene: Scene;
    private _waterMaterial: StandardMaterial;
    private _treetopMaterial: StandardMaterial;
    private _treeMaterial: StandardMaterial;
    private _boatMaterial: StandardMaterial;
    private _rodMaterial: StandardMaterial;
    private _rockMaterial: StandardMaterial;
    private _iceMaterial: StandardMaterial;

    private _boat?: AbstractMesh;
    private _fishingRod?: AbstractMesh;

    private _bobAnimation: Animation;
    private _swayAnimation: Animation;

    private _boatAnimatable?: Animatable;

    private _locationMeshes: Mesh[] = [];

    public constructor(scene: Scene, waterMaterial: StandardMaterial) {
        this._scene = scene;
        this._waterMaterial = waterMaterial;
        this._treetopMaterial = new StandardMaterial('treetopMaterial', this._scene);
        this._treetopMaterial.diffuseColor = Color3.Green();

        this._treeMaterial = new StandardMaterial('treeMaterial', this._scene);
        this._treeMaterial.diffuseColor = Color3.FromHexString('#892E1A');

        this._boatMaterial = new StandardMaterial('boatMaterial', this._scene);
        this._boatMaterial.diffuseColor = Color3.FromHexString('#FFFFFF');

        this._rodMaterial = new StandardMaterial('rodMaterial', this._scene);
        this._rodMaterial.diffuseColor = Color3.FromHexString('#AD8C65');

        this._rockMaterial = new StandardMaterial('rockMaterial', this._scene);
        this._rockMaterial.diffuseColor = Color3.Gray();

        this._iceMaterial = new StandardMaterial('icMaterial', this._scene);
        this._iceMaterial.diffuseColor = Color3.White();

        const easingFunction = new SineEase();
        easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

        this._bobAnimation = new Animation("yBob", "position.y", this._frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
        this._bobAnimation.setEasingFunction(easingFunction);
        this._bobAnimation.setKeys([
            { frame: 0, value: 0 },
            { frame: this._frameRate, value: -0.05 },
            { frame: 2 * this._frameRate, value: 0 }
        ]);

        this._swayAnimation = new Animation("sway", "rotation.z", this._frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
        this._swayAnimation.setEasingFunction(easingFunction);
        this._swayAnimation.setKeys([
            { frame: 0, value: 0.008 },
            { frame: 0.7 * this._frameRate, value: -0.008 },
            { frame: 1.4 * this._frameRate, value: 0.008 }
        ]);
    }

    public createBoat(scene: Scene): void {
        SceneLoader.ImportMeshAsync('', process.env.PUBLIC_URL + '/models/boat.obj').then((result) => {
            this._boat = result.meshes[0];
            this._boat.scaling = new Vector3(0.01, 0.01, 0.01);
            this._boat.rotation = new Vector3(0, Math.PI, 0);
            this._boat.material = this._boatMaterial;
            this._boat.animations.push(this._bobAnimation);
            this._boat.animations.push(this._swayAnimation);
            this._boatAnimatable = scene.beginAnimation(this._boat, 0, 2 * this._frameRate, true, 0.2);
        });
    }

    public createFishingRod(scene: Scene): void {
        SceneLoader.ImportMeshAsync('', process.env.PUBLIC_URL + '/models/fishing_rod.obj').then((result) => {
            this._fishingRod = result.meshes[0];
            this._fishingRod.scaling = new Vector3(0.01, 0.01, 0.01);
            this._fishingRod.position = new Vector3(0.5, 1.5, 3);
            this._fishingRod.rotation = new Vector3(
                (Math.PI / 180) * 200,
                (Math.PI / 180) * 175,
                (Math.PI / 180) * 270
            );
            this._fishingRod.material = this._rodMaterial;
            this.setFishingRodVisible(false);

            // animation
            const frameRate = 10;
            const bobAnimation = new Animation("yBob", "position.y", frameRate, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
            const easingFunction = new SineEase();
            easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
            bobAnimation.setEasingFunction(easingFunction);
            bobAnimation.setKeys([
                { frame: 0, value: this._fishingRod.position.y },
                { frame: frameRate, value: this._fishingRod.position.y - 0.1 },
                { frame: 2 * frameRate, value: this._fishingRod.position.y }
            ]);
            this._fishingRod.animations.push(bobAnimation);
        });
    }

    public setFishingRodVisible(visible: boolean): void {
        if (this._fishingRod) {
            this._fishingRod.isVisible = visible;
        }
    }

    public setFishingRodAnimated(animated: boolean): void {
        if (this._fishingRod) {
            if (animated) {
                this._scene.beginAnimation(this._fishingRod, 0, 2 * 10, true, 5);
            } else {
                this._scene.stopAnimation(this._fishingRod);
            }
        }
    }

    public createIsland(front: boolean): Mesh {
        const island = MeshBuilder.CreateSphere("island", {diameter: 2 }, this._scene);
        island.position = this._propPosition(front);
        const scale = _.random(0.6, 1.0);
        island.scaling =  new Vector3(scale, scale, scale);
        const islandMaterial = new StandardMaterial('islandMaterial', this._scene);
        islandMaterial.diffuseColor = Color3.FromHexString('#D9DD95');
        island.material = islandMaterial;


        this.createTree(this._scene, island);
        this.createTree(this._scene, island);
        this.createTree(this._scene, island);

        this._locationMeshes.push(island);
        return island;
    }

    public createTree(scene: Scene, parent: Mesh): Mesh {
        const tree = MeshBuilder.CreateCylinder('treetrunk', { height: 1.5, diameterTop: 0.1, diameterBottom: 0.2, tessellation: 5 }, scene);
        tree.parent = parent;
        tree.position = new Vector3(_.random(-0.5, 0.5), 1.5, _.random(-0.5, 0.5));
        tree.material = this._treeMaterial;

        const scale = _.random(0.75, 1.0);
        tree.scaling =  new Vector3(scale, scale, scale);
        tree.rotateAround(tree.position, Vector3.Forward(), _.random(-0.1, 0.1, true));

        const treeTop = MeshBuilder.CreateSphere('treetop', { segments: 5, diameter: 0.3 }, scene);
        treeTop.parent = tree;
        treeTop.position = new Vector3(0.0, 0.65, 0);
        treeTop.material = this._treetopMaterial;

        const treeMid = MeshBuilder.CreateSphere('treemid', { segments: 3, diameter: 0.2 }, scene);
        treeMid.parent = tree;
        treeMid.position = new Vector3(_.random(-0.125, 0.125), _.random(0.2, 0.4), _.random(-0.125, 0.125));
        treeMid.material = this._treetopMaterial;

        const treeLow = MeshBuilder.CreateSphere('treelow', { segments: 3, diameter: 0.2 }, scene);
        treeLow.parent = tree;
        treeLow.position = new Vector3(_.random(-0.125, 0.125), _.random(0.1, 0.2), _.random(-0.125, 0.125));
        treeLow.material = this._treetopMaterial;

        return tree;
    }

    private _createRock(front: boolean): void {
        const rock: Mesh = MeshBuilder.CreateBox('rock', { size: 1.2 }, this._scene);
        rock.material = this._rockMaterial;
        rock.rotation = new Vector3(Math.PI / 4, _.random(Math.PI * 2), Math.PI / 4);
        rock.position = this._propPosition(front);

        this._locationMeshes.push(rock);
    }

    private _createMountain(): void {
        const mountain: Mesh = MeshBuilder.CreateBox('mountain', { size: 50 }, this._scene);
        mountain.material = this._treetopMaterial;
        mountain.rotation = new Vector3(Math.PI / 4, _.random(Math.PI * 2), Math.PI / 4);
        mountain.position = this._propPosition(true);
        mountain.position = new Vector3(_.random(-100, 100), -25, -200);

        this._locationMeshes.push(mountain);
    }

    private _createSmallMountain(front: boolean): void {
        const mountain: Mesh = MeshBuilder.CreateBox('smallMountain', { size: 10 }, this._scene);
        mountain.material = this._treetopMaterial;
        mountain.rotation = new Vector3(Math.PI / 4, _.random(Math.PI * 2), Math.PI / 4);
        // mountain.position = this._propPosition(true);
        if (front) {
            mountain.position = new Vector3(_.random(-150, 150), -9, -200);
        } else {
            mountain.position = new Vector3(_.random(-150, 150), -9, 200);
        }

        this._locationMeshes.push(mountain);
    }

    private _createIce(front: boolean): void {
        const ice: Mesh = MeshBuilder.CreateBox('ice', { size: 1.2 }, this._scene);
        ice.material = this._iceMaterial;
        ice.rotation = new Vector3(Math.PI / 30, _.random(Math.PI * 2), Math.PI / 50);
        ice.position = this._propPosition(front);

        this._locationMeshes.push(ice);
    }

    public startMoving(location: string | undefined): void {
        this._scene.animationTimeScale = 7.0;
        setTimeout(() => {
            this._locationMeshes.forEach(mesh => {
                mesh.dispose();
            });
            setTimeout(() => {
                this.createLocation(location);
            }, 1000);
        }, 1000);
    }

    public createLocation(location: string | undefined): void {
        this._locationMeshes = [];

        if (location === 'West Coast') {
            this._createRock(true);
            this._createRock(true);
            this._createRock(true);
            this._createRock(false);
            this._createRock(false);
            this._createMountain();
            this._createMountain();
            this._createMountain();
            this._waterMaterial.diffuseColor = Color3.FromHexString('#0E63D0');
        } else if (location === 'Greater Atlantic') {
            // Nothing
            this._waterMaterial.diffuseColor = Color3.FromHexString('#0041C2');
        } else if (location === 'Pacific Islands') {
            this._createSmallMountain(true);
            this._createSmallMountain(true);
            this._createSmallMountain(true);
            this._createSmallMountain(true);
            this._createSmallMountain(true);
            this._createSmallMountain(false);
            this._createSmallMountain(false);
            this._waterMaterial.diffuseColor = Color3.FromHexString('#2EB2E5');
        } else if (location === 'Alaska') {
            this._createIce(true);
            this._createIce(true);
            this._createIce(true);
            this._createIce(true);
            this._createIce(false);
            this._createIce(false);
            this._waterMaterial.diffuseColor = Color3.FromHexString('#4A87D6');
        } else if (location === 'Southeast') {
            this.createIsland(true);
            this.createIsland(true);
            this.createIsland(true);
            this.createIsland(true);
            this.createIsland(false);
            this.createIsland(false);
            this._waterMaterial.diffuseColor = Color3.FromHexString('#2897C2');
        } else {
            // default
            this.createIsland(true);
            this.createIsland(true);
            this.createIsland(true);
            this.createIsland(true);
        }
    }

    public endMoving(): void {
        this._scene.animationTimeScale = 1.0;
    }

    private _propPosition(front: boolean): Vector3 {
        if (front) {
            return new Vector3(_.random(-15, 15), -0.3, _.random(-45, -30));
        }
        return new Vector3(_.random(-15, 15), -0.3, _.random(45, 30));
    }
}